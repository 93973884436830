const initState = {
	fieldId: null,
	cropOptions: [],
	loading: false
};

const appStates = (state = initState, action: { type: string; fieldId: number; cropOptions: any; loading: boolean }) => {
	switch (action.type) {
		case "SET_FIELD":
			return {
				...state,
				fieldId: action.fieldId
			};
		case "SET_CROP_OPTIONS":
			return {
				...state,
				cropOptions: action.cropOptions
			};
		case "SET_LOADING":
			return {
				...state,
				loading: action.loading
			};
		default:
			return state;
	}
};

export default appStates;
