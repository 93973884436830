export const fieldNameTooltip = "Include the name of the crop in the field name for tracking purposes.";
export const fieldNameEditTooltip = "Edit the name of the crop, if desired";

export const yieldTooltip = "If yield changes did occur due to soil health practice adoption, enter the average yield \"Before\" then \"After\" " +
	"soil health practice adoption. Then select one soil health practice or \"combined practices\" associated with this yield change. Any yield" +
	" change attributed to cover crops will be applied to entire field acreage by cash crop. Leave both \"Before\" and \"After\" cells blank if" +
	" yield did not change with soil health practice adoption.";
export const seedsTooltip = "If seed cost did change due to soil health practice adoption, enter the average seed cost \"Before\" then \"After\" soil" +
	" health practice adoption. Then select one soil health practice or \"combined practices\" associated with this seed cost change. Leave " +
	"both \"Before\" and \"After\" cells blank if seed cost did not change with soil health practice adoption.";
export const herbicideTooltip = "Include the cost of equipment and material. If herbicide cost did change due to soil health practice adoption, " +
	"enter the average herbicide cost \"Before\" then \"After\" soil health practice adoption. Then select one soil health practice or " +
	"\"combined practices\" associated with this herbicide cost change. Leave both \"Before\" and \"After\" cells blank if herbicide cost did" +
	" not change with soil health practice adoption.";
export const insecticideTooltip = "Include the cost of equipment and material. If insecticide cost did change due to soil health practice " +
	"adoption, enter the average insecticide cost \"Before\" then \"After\" soil health practice adoption. Then select one soil health practice " +
	"or \"combined practices\" associated with this insecticide cost change. Leave both \"Before\" and \"After\" cells blank if insecticide did " +
	"not change with soil health practice adoption.";
export const fungicideTooltip = "Include the cost of equipment and material. If fungicide cost did change due to soil health practice adoption, " +
	"enter the average fungicide cost \"Before\" then \"After\" soil health practice adoption. Then select one soil health practice or" +
	" \"combined practices\" associated with this fungicide cost change. Leave both \"Before\" and \"After\" cells blank if fungicide did " +
	"not change with soil health practice adoption.";
export const tillageTooltip = "If tillage practices did change to improve soil health, select the machinery type and enter the number of " +
	"passes in any of the three \"Before\" and/or \"After\" cells.";
export const fertilizerTooltip = "If nutrient management practices did change to improve soil health, select the fertilizer application" +
	" equipment and enter the quantity of fertilizer applied (pounds of Anhydrous Ammonia, UAN (28%), UAN (32%), DAP, MAP and/or Potash). " +
	"Users may enter before and/or after information for up to three types of application equipment and fertilizer combinations. " +
	"Select \"None\" in the \"Fertilizer\" box for either \"Before\" or \"After\" if no fertilizer is being applied.";
export const ccAcresTooltip = "Acres planted cannot exceed crop acreage.";
export const ccSeedsTooltip = "Enter zero for \"before\" if you have not used cover crops in the past.";
export const ccEstTooltip = "Enter zero for \"before\" if you have not used cover crops in the past.";
export const ccTermTooltip = "Enter zero for \"before\" if you have not used cover crops in the past.";
