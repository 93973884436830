import React, {useState, useEffect, useRef} from "react";
import "../../styles/home.css";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ComponentTextfield from "../styledControls/ComponentTextfield";
import config from "../../app.config";

interface TillageOption{
	id: number;
	name: string;
}

interface TillageType{
	idx: number;
	tillageOptions: [] | [TillageOption];
	selectedId: number;
	selectedPasses: null | number;
	parentCallBack: any; // (id: null | number) => void;
}

const useStyle = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		padding: "2px 10px",
		minWidth: "365px"
	},
}));

function usePrevious(value: any) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}


const Tillage = ({idx, tillageOptions, selectedId, selectedPasses, parentCallBack}: TillageType) => {

	const classes = useStyle();
	const [selectedOption, setSelectedOption] = useState<null | TillageOption>(null);
	const [id, setId] = useState<null | number>(0);
	const [passes, setPasses] = useState<null | number>(null);

	const prevTillage: any = usePrevious({selectedId, selectedPasses});

	useEffect(() => {
		tillageOptions.forEach((row: TillageOption) => {
			if (row.id === selectedId){
				setSelectedOption({id: row.id, name: row.name});
				setId(row.id);
			}
		});
	}, [selectedId, tillageOptions]);

	useEffect(() => {
		if (id !== null && id === 0){
			setPasses(null);
		}
	}, [id]);

	useEffect(() => {
		setId(selectedId);
	}, [selectedId]);

	useEffect(() => {
		setPasses(selectedPasses);
	}, [selectedPasses]);

	useEffect(() => {

		if (prevTillage && (prevTillage.selectedId !== id || prevTillage.selectedPasses !== passes)) {
			parentCallBack(idx, {id: id, passes: passes});
		}
	}, [idx, id, passes, prevTillage, parentCallBack]);

	return (
		<div>
			<FormControl variant="outlined" className={classes.formControl}>
				<Autocomplete options={tillageOptions}
						  value={tillageOptions ? selectedOption : null}
						  getOptionSelected={(option: TillageOption, value: TillageOption) => option.id === value.id}
						  getOptionLabel={(option: TillageOption) => option.name}
						  renderInput={(params) =>
							  <TextField {...params} label="Machinery" variant="outlined" InputLabelProps={{shrink: true}}/>
						  }
						  onChange={(event, value: null | TillageOption) => {
							  setSelectedOption(value ? value : config.defaultTillageOption);
							  setId(value ? value.id : 0);
						  }}
				/>
			</FormControl>

			<ComponentTextfield name="Passes" selectedValue={selectedPasses} parentCallBack={setPasses}/>
		</div>
	);
};


export default Tillage;
