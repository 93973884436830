import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import {toUsd} from "../utils/common";
import {apiClient} from "../utils/axiosClients";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(() => ({
	table: {
		minWidth: 400,
		maxWidth: 600,
		borderTop: "2px solid #000000",
		borderRadius: "5px",
		margin: "0px 16px 0px 16px",
		height: "fit-content"
	},
	tableSummary: {
		maxWidth: 1200 + 16 + 16,
		borderTop: "2px solid #000000",
		borderRadius: "5px",
		margin: "2px 16px"
	},
	tableHeaderRow: {
		height: "24px",
	},
	tableHeaderCellGreen: {
		height: "28px",
		textAlign: "center",
		backgroundColor: "#75AA50",
		color: "#FFFFFF",
		fontSize: "1.25rem",
		fontWeight: 700,
		letterSpacing: "1.15px",
		border: "1px solid #000000",
	},
	tableSubHeaderCellGreen: {
		textAlign: "center",
		backgroundColor: "#DAE78B",
		color: "#000000",
		fontSize: "1.075rem",
		fontWeight: 600,
		border: "1px solid #000000",
	},
	tableHeaderCellRed: {
		height: "28px",
		textAlign: "center",
		backgroundColor: "#B90D35",
		color: "#FFFFFF",
		fontSize: "1.25rem",
		fontWeight: 700,
		letterSpacing: "1.15px",
		border: "1px solid #000000",
	},
	tableSubHeaderCellRed: {
		textAlign: "center",
		backgroundColor: "#DC916A",
		color: "#000000",
		fontSize: "1.075rem",
		fontWeight: 600,
		border: "1px solid #000000",
	},
	quadrantHeaderItemCell: {
		textAlign: "center",
		color: "#000000",
		fontSize: "0.80rem",
		fontWeight: 600,
		minWidth: 300,
		border: "1px solid #000000",
	},
	quadrantHeaderNumberCell: {
		textAlign: "right",
		color: "#000000",
		fontSize: "0.85rem",
		fontWeight: 600,
		border: "1px solid #000000",
	},
	netCellNameGreen: {
		textAlign: "right",
		backgroundColor: "#DAE78B",
		color: "#000000",
		fontSize: "1rem",
		fontWeight: 600,
		border: "1px solid #000000",
	},
	netCellValueGreen: {
		textAlign: "right",
		backgroundColor: "#DAE78B",
		color: "#000000",
		fontSize: "1rem",
		fontWeight: 600,
		border: "1px solid #000000",
	},
	netCellNameRed: {
		textAlign: "right",
		backgroundColor: "#DC916A",
		color: "#000000",
		fontSize: "1rem",
		fontWeight: 600,
		border: "1px solid #000000",
	},
	netCellValueRed: {
		textAlign: "right",
		backgroundColor: "#DC916A",
		color: "#000000",
		fontSize: "1rem",
		fontWeight: 600,
		border: "1px solid #000000",
	},
	tableItemCell: {
		textAlign: "left",
		fontSize: "0.95rem",
		border: "1px solid #000000",
	},
	tableNumberCell: {
		textAlign: "right",
		fontSize: "0.95rem",
		border: "1px solid #000000",
	},
	totalQuadrantCellName: {
		textAlign: "left",
		fontSize: "0.95rem",
		border: "1px solid #000000",
		fontWeight: 600
	},
	totalQuadrantCellValue: {
		textAlign: "right",
		fontSize: "0.95rem",
		border: "1px solid #000000",
		fontWeight: 600
	},
	summaryCell: {
		textAlign: "center",
		color: "#FFFFFF",
		fontSize: "1.125rem",
		fontWeight: 600,
		border: "1px solid #000000",
	}

}));

interface props{
	fieldId: number | null;
}

interface Field{
	id: number;
	name: string;
	total_acres: number;
	total_cc_acres: number;
}

interface pbResults{
	positive_effects: {
		increase_in_income: Array<{}>;
		decrease_in_costs: Array<{}>;
		total_increased_income: number;
		total_decreased_cost: number;
		annual_total_increased_net_income: number;
		annual_per_acre_increased_net_income: number | null;
	};
	negative_effects: {
		decrease_in_income: Array<{}>;
		increase_in_costs: Array<{}>;
		total_decreased_income: number;
		total_increased_cost: number;
		annual_total_decreased_net_income: number;
		annual_per_acre_decreased_net_income: number | null;
	};
	net_income: number;
	net_income_per_acre: number | null;
	return_on_investment: number;
}

const PartialBudgetQuadrants = ({fieldId}: props ) => {
	const classes = useStyles();

	const defaultPbResults = {
		positive_effects: {
			increase_in_income: [],
			decrease_in_costs: [],
			total_increased_income: 0,
			total_decreased_cost: 0,
			annual_total_increased_net_income: 0,
			annual_per_acre_increased_net_income: 0
		},
		negative_effects: {
			decrease_in_income: [],
			increase_in_costs: [],
			total_decreased_income: 0,
			total_increased_cost: 0,
			annual_total_decreased_net_income: 0,
			annual_per_acre_decreased_net_income: 0
		},
		net_income: 0,
		net_income_per_acre: 0,
		return_on_investment: 0
	};

	let quadrantHeader = (<TableRow>
		<TableCell className={classes.quadrantHeaderItemCell} >
			ITEM
		</TableCell>
		<TableCell className={classes.quadrantHeaderNumberCell}>
			PER ACRE
		</TableCell>
		<TableCell className={classes.quadrantHeaderNumberCell}>
			TOTAL
		</TableCell>
	</TableRow>);

	let noneRow = (<TableRow>
		<TableCell className={classes.tableItemCell}> None </TableCell>
		<TableCell className={classes.tableNumberCell}>  </TableCell>
		<TableCell className={classes.tableNumberCell}>  </TableCell>
	</TableRow>);

	const [checked, setChecked] = useState(false);
	const [field, setField] = useState<Field>({id: 0, name: "", total_acres: 0, total_cc_acres: 0});
	const [pbResults, setPbResults] = useState<pbResults>(defaultPbResults);

	React.useEffect(() => {
		let urlResults = "results";
		if (!checked) {
			urlResults = `results/${fieldId}`;
		}

		const fetchResults = async () => {
			await apiClient.get(
				urlResults
			).then( result => {
				if (result.status === 200) {
					setField({
						id: result.data.field_info.crop_id,
						name: result.data.field_info.crop_name,
						total_acres: result.data.field_info.total_acres,
						total_cc_acres: result.data.field_info.total_cc_acres
					});
					setPbResults(result.data.partial_budget_results);
				}
				else {
					console.error(`Failure fetching results. API Response: ${result}`);
				}
			}).catch((e) => {
				console.error(`Error fetching results from api: ${e}`);
			});
		};
		fetchResults();
	}, [fieldId, checked]);

	let positiveQ1 = pbResults.positive_effects.increase_in_income;
	let positiveQ2 = pbResults.positive_effects.decrease_in_costs;
	let negativeQ1 = pbResults.negative_effects.decrease_in_income;
	let negativeQ2 = pbResults.negative_effects.increase_in_costs;
	let totalIncreasedIncome = pbResults.positive_effects.total_increased_income;
	let totalDecreasedCost = pbResults.positive_effects.total_decreased_cost;
	let annualTotalIncreasedNetIncome = pbResults.positive_effects.annual_total_increased_net_income;
	let annualPerAcreIncreasedNetIncome = pbResults.positive_effects.annual_per_acre_increased_net_income;
	let totalDecreasedIncome = pbResults.negative_effects.total_decreased_income;
	let totalIncreasedCost = pbResults.negative_effects.total_increased_cost;
	let annualTotalDecreasedNetIncome = pbResults.negative_effects.annual_total_decreased_net_income;
	let annualPerAcreDecreasedNetIncome = pbResults.negative_effects.annual_per_acre_decreased_net_income;
	let netIncome = pbResults.net_income;
	let netIncomePerAcre = pbResults.net_income_per_acre;
	let returnOnInvestment = pbResults.return_on_investment;

	return (
		<div>

			<Grid container style={{maxWidth: "1250px"}}>
				<Grid item xs={6} style={{padding: "5px 40px", textAlign: "left"}}>
					<b>Crop name:</b> {checked ? <span>all</span> : <span>{field.name}</span>}<br />
					<b>Acres:</b> {(field.total_acres !== 0) ? field.total_acres.toFixed(2) : "NA"} <br/>
					<b>Cover crop acres:</b> {(field.total_cc_acres !== 0) ? field.total_cc_acres.toFixed(2) : "NA"}
				</Grid>
				<Grid item xs={6} style={{padding: "5px 20px", textAlign: "right"}}>
					<FormControlLabel
						value={checked}
						control={<Checkbox onChange={() => setChecked(!checked)} color="primary" />}
						label="Calculate for all crops"
						labelPlacement="start"
					/>
				</Grid>
			</Grid>


			<Grid container justify="center" alignItems="center">
				<Grid item xs={12} style={{paddingBottom: "0px"}}>
					<Table>
						<TableRow>
							<TableCell style={{alignItems: "center", borderBottom: "none", margin: 0}}>
								<div style={{display: "flex", flexFlow: "wrap"}}>
									<Table className={classes.table} size="small" aria-label="Positive Effects">
										<TableHead>
											<TableRow className={classes.tableHeaderRow} key="hdrRowp1">
												<TableCell className={classes.tableHeaderCellGreen} colSpan={3}>
														Positive Effects
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow key="hdrRowp2">
												<TableCell className={classes.tableSubHeaderCellGreen} colSpan={3}>
														Increase in Income
												</TableCell>
											</TableRow>
											{quadrantHeader}
											{
												positiveQ1.length > 0 ?
													positiveQ1.map((res: any, idx: number) => {
														return (
															<TableRow key={`q1${idx}`}>
																<TableCell className={classes.tableItemCell}> {res.title} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.change_per_acre)} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.total_change)} </TableCell>
															</TableRow>
														);
													})
													:
													noneRow
											}
											<TableRow>
												<TableCell className={classes.totalQuadrantCellName} colSpan={2}> Total Increased Income </TableCell>
												<TableCell className={classes.totalQuadrantCellValue}> {toUsd(totalIncreasedIncome)} </TableCell>
											</TableRow>

											<TableRow key="hdrRowp3">
												<TableCell className={classes.tableSubHeaderCellGreen} colSpan={3}>
														Decrease in Cost
												</TableCell>
											</TableRow>
											{quadrantHeader}

											{
												positiveQ2.length > 0 ?
													positiveQ2.map((res: any, idx: number) => {
														return (
															<TableRow key={`q2${idx}`}>
																<TableCell className={classes.tableItemCell}> {res.title} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.change_per_acre)} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.total_change)} </TableCell>
															</TableRow>
														);
													}) :
													noneRow
											}

											<TableRow>
												<TableCell className={classes.totalQuadrantCellName} colSpan={2}> Total Decreased Cost </TableCell>
												<TableCell className={classes.totalQuadrantCellValue}> {toUsd(totalDecreasedCost)}</TableCell>
											</TableRow>

											<TableRow>
												<TableCell colSpan={2} className={classes.netCellNameGreen}>
														Annual Total Increased Net Income
												</TableCell>
												<TableCell className={classes.netCellValueGreen}>
													{toUsd(annualTotalIncreasedNetIncome)}
												</TableCell>

											</TableRow>

											<TableRow>
												<TableCell colSpan={2} className={classes.netCellNameGreen}>
														Annual Per Acre Increased Net Income
												</TableCell>
												<TableCell className={classes.netCellValueGreen}>
													{toUsd(annualPerAcreIncreasedNetIncome)}
												</TableCell>

											</TableRow>

										</TableBody>
									</Table>

									<Table className={classes.table} size="small" aria-label="Negative Effects">
										<TableHead>
											<TableRow className={classes.tableHeaderRow} key="hdrRown1">
												<TableCell className={classes.tableHeaderCellRed} colSpan={3}>
														Negative Effects
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow key="hdrRown2">
												<TableCell className={classes.tableSubHeaderCellRed} colSpan={3}>
														Decrease in Income
												</TableCell>
											</TableRow>
											{quadrantHeader}
											{
												negativeQ1.length > 0 ?
													negativeQ1.map((res: any, idx: number) => {
														return (
															<TableRow key={`q3${idx}`}>
																<TableCell className={classes.tableItemCell}> {res.title} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.change_per_acre)} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.total_change)} </TableCell>
															</TableRow>
														);
													}) :
													noneRow
											}
											<TableRow>
												<TableCell className={classes.totalQuadrantCellName} colSpan={2}> Total Decreased Income </TableCell>
												<TableCell className={classes.totalQuadrantCellValue}> {toUsd(totalDecreasedIncome)} </TableCell>
											</TableRow>

											<TableRow key="hdrRown3">
												<TableCell className={classes.tableSubHeaderCellRed} colSpan={3}>
														Increase in Cost
												</TableCell>
											</TableRow>
											{quadrantHeader}

											{
												negativeQ2.length > 0 ?
													negativeQ2.map((res: any, idx: number) => {
														return (
															<TableRow key={`q4${idx}`}>
																<TableCell className={classes.tableItemCell}> {res.title} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.change_per_acre)} </TableCell>
																<TableCell className={classes.tableNumberCell}> {toUsd(res.total_change)} </TableCell>
															</TableRow>
														);
													}) :
													noneRow

											}

											<TableRow>
												<TableCell className={classes.totalQuadrantCellName} colSpan={2}> Total Increased Cost </TableCell>
												<TableCell className={classes.totalQuadrantCellValue}> {toUsd(totalIncreasedCost)}</TableCell>
											</TableRow>

											<TableRow>
												<TableCell colSpan={2} className={classes.netCellNameRed}>
														Annual Total Decreased Net Income
												</TableCell>
												<TableCell className={classes.netCellValueRed}>
													{toUsd(annualTotalDecreasedNetIncome)}
												</TableCell>

											</TableRow>

											<TableRow>
												<TableCell colSpan={2} className={classes.netCellNameRed}>
														Annual Per Acre Decreased Net Income
												</TableCell>
												<TableCell className={classes.netCellValueRed}>
													{toUsd(annualPerAcreDecreasedNetIncome)}
												</TableCell>

											</TableRow>

										</TableBody>
									</Table>
								</div>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{alignItems: "center", borderBottom: "none", margin: 0, paddingTop: 0}}>
								<div>
									<Table className={classes.tableSummary} size="small"
											   aria-label="Positive Effects"
											   style={{backgroundColor: "#75AA50"}}>
										{/*style={results.net_income > 0 ? {backgroundColor: "#75AA50"} : {backgroundColor: "#B90D35"}}>*/}
										<TableRow>
											<TableCell className={classes.summaryCell}>
													Annual Change in Total Net Income = {toUsd(netIncome)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell className={classes.summaryCell}>
													Annual Change in Per Acre Net Income = {toUsd(netIncomePerAcre)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell className={classes.summaryCell}>
													Return on Investment = {returnOnInvestment.toFixed(2)}%
											</TableCell>
										</TableRow>
									</Table>
								</div>
							</TableCell>
						</TableRow>
					</Table>
				</Grid>
			</Grid>
		</div>

	);

};

export default PartialBudgetQuadrants;
