import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import Layout from "./Layout";
import "../../styles/home.css";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import apiClient from "../../utils/apiClient";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		justifyContent: "center",
	},
	form: {
		width: "20em",
		maxWidth: "100vw",
		padding: "1em",
	},
	input: {
		margin: theme.spacing(1),
	},
	horizontalView: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: theme.spacing(1),
	},
}));

const Login = () => {
	const classes = useStyles(),
		dispatch = useDispatch(),
		history = useHistory(),
		[formState, setFormState] = useState({username: "", password: ""}),
		[status, setStatus] = useState("");

	const handleInputChange = (e: any) => {
		const {name, value} = e.target;
		setStatus("");
		setFormState({...formState, [name]: value});
	};

	const login = async (e: any) => {
		e.preventDefault();
		setStatus("pending");
		const form = new FormData();
		form.append("username", formState.username);
		form.append("password", formState.password);
		const result = await apiClient.post("login", form);
		if (result.data.user) {
			dispatch({type: "AUTH_LOGIN", payload: result.data.user});
			history.push("/calculator");
		}
		else {
			setStatus("error");
		}
	};

	return (
		<Layout>
			<div className={classes.container}>
				<form onSubmit={login} className={classes.form}>
					<h2 className="secondary-color">Log In</h2>
					<TextField
						name="username"
						label="Email"
						variant="outlined"
						fullWidth
						error={status === "error"}
						className={classes.input}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={handleInputChange}
						value={formState.username}
					/>
					<TextField
						name="password"
						type="password"
						fullWidth
						error={status === "error"}
						className={classes.input}
						label="Password"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						onChange={handleInputChange}
						value={formState.password}
						helperText={
							status === "error" ? "Invalid username or password" : undefined
						}
					/>
					<div className={classes.horizontalView}>
						<Button color="secondary" component={Link} to="/register">
							Register
						</Button>
						<Button
							variant="contained"
							disabled={status === "pending"}
							color="primary"
							type="submit"
						>
							Log In
						</Button>
					</div>
				</form>
			</div>
		</Layout>
	);
};

export default Login;
