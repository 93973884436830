import {useEffect} from "react";
import apiClient from "../../utils/apiClient";
import {useDispatch} from "react-redux";


export default function CheckAuth() {
	const dispatch = useDispatch();
	useEffect(() => {
		checkAuth();
		setInterval(checkAuth, 15 * 60 * 1000);
		async function checkAuth() {
			const {data} = await apiClient.get("login");
			if (data.user) {
				dispatch({type: "AUTH_LOGIN", payload: data.user});
			}
			else {
				dispatch({type: "AUTH_LOGOUT"});
			}
		}
	});
	return null;
}
