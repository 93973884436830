import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import {rootReducer} from "./reducers";
import {configureStore} from "@reduxjs/toolkit";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

const store = configureStore({
	reducer: rootReducer
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#006400"
		},
		secondary: {
			main: "#00008b"
		}
	}
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
