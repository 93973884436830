import React from "react";
import Layout from "./common/Layout";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PBAScreenshot from "../images/pba-screenshot.png";
import {Button} from "@material-ui/core";

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
	},
	indicator: {
		backgroundColor: "darkgreen",
	},
	tabText: {
		fontWeight: 600,
	},
	underlineText: {
		textDecoration: "underline",
	},
}));

const LandingPage = () => {
	const classes = useStyles();

	return (
		<Layout>
			<Grid container item xs={12}>
				<Grid className="home-content" item xs={5}>
					<div>
						<h2 className="secondary-color">
							Welcome to the Soil Health Economic Calculator
						</h2>
						<br />
						<div className="paras">
							This online Soil Health Economic Calculator (SHEC) Tool (Version
							2, 2022) can be used by row-crop farms growing corn, soybeans,
							and/or wheat to evaluate the average annual economic effects of
							three soil health practices:
							<br />
							<ol>
								<li>No-till or Reduced Tillage</li>
								<li>Cover Crops, and/or</li>
								<li>Nutrient Management.</li>
							</ol>
							The effects analyzed are: yield, seeds cost, chemical input costs,
							tillage practices, nutrient management practices, cover crop seeds
							cost, and cover crop establishment and termination costs.
							<br />
							<br />
							Farmers or their advisors can use this tool to conduct a
							retrospective or predictive economic analysis of soil health
							practice adoption.
							<ul>
								<li>
									A{" "}
									<span className={classes.underlineText}>
										retrospective analysis
									</span>{" "}
									is for farmers who have successfully adopted one or more soil
									health practices. They should enter information based on their
									actual farm records and experience.
								</li>
								<li>
									A{" "}
									<span className={classes.underlineText}>
										predictive analysis
									</span>{" "}
									is for farmers who have not yet adopted a soil health practice
									or have just begun experimenting with soil health practices.
									They can use the tool to estimate how adoption of one or more
									practices could affect their bottom line.
								</li>
							</ul>
							More information about the tool is provided on the "References"
							and "About" pages. Once users enter the tool using the link below,
							Guidance on how to use the tool is provided.
						</div>

						<span className="paras">
							<span style={{fontWeight: 600, fontSize: "1.125rem"}}>
								Enter the tool:&nbsp;{" "}
							</span>
							<Link
								to="/calculator"
								style={{color: "inherit", textDecoration: "none"}}
							>
								<Button
									style={{
										height: "40px",
										fontSize: "1.0rem",
										fontWeight: 600,
										textTransform: "capitalize",
										backgroundColor: "green",
										color: "white",
									}}
								>
									Soil Health Economic Calculator
								</Button>
							</Link>
						</span>
					</div>
				</Grid>
				<Grid item xs={7}>
					<div>
						<h3 className="secondary-color">How does the Tool work?</h3>
						<br />
						<img
							src={PBAScreenshot}
							alt="Partial Budget Analysis"
							width="90%"
						/>
					</div>
					<p className="paras" style={{width: "82%", paddingRight: "20px"}}>
						In the example above, the Soil Health Economic Calculator estimates
						that the successful adoption of reduced tillage, cover crops, and
						nutrient management on a 1,400-acre corn-soybean farm resulted in a
						$35.32 per acre increase in annual net income and a 144% increase in
						Return on Investment.
					</p>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default LandingPage;
