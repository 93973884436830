import React from "react";
import Layout from "./Layout";
import "../../styles/home.css";
import {Button, Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
	mainText: {
		margin: "0px",
		padding: "0px 0px",
	},
	titleText: {
		color: "#455A64",
		paddingBottom: "20px",
		paddingTop: "20px",
		textAlign: "center",
	},
	paragraphText: {
		color: "#455A64",
		paddingBottom: "10px",
		paddingLeft: "50px",
		paddingRight: "50px",
		textAlign: "justify",
	},
	underlineText: {
		textDecoration: "underline",
	},
});

const About = () => {
	const classes = useStyles();

	return (
		<Layout>
			<Grid container item xs={12}>
				<Grid className={classes.mainText} item xs={12}>
					<div>
						<h2 className="secondary-color">
							About the Soil Health Economic Calculator
						</h2>
						<br />
						<p className={classes.paragraphText}>
							The Online Soil Health Economic Calculator (O-SHEC) Tool evaluates
							the effect of adopting any combination of No-Till or Reduced
							Tillage, Cover Crops, and Nutrient Management on a farm’s annual
							net returns using partial budget analysis (PBA). The PBA
							calculates the change in net income due to soil health practice(s)
							based on a combination of user inputs and standardized cost and
							price information built into the Tool.
						</p>
						<p className={classes.paragraphText}>
							A PBA is a cost-benefit analysis that isolates the costs and
							benefits associated with the evaluated soil health practice(s).
							Any changes not related to the soil health practices should not be
							evaluated. PBA results are expressed on an annual basis per acre
							and for the entire study area comparing costs and benefits that
							occurred "Before" and "After" soil health practice adoption. This
							analysis is best suited for mid-western states, as the default
							cost and price information is sourced from University of Illinois
							and University of Iowa datasets, but could be used elsewhere, as
							farmers can choose to input their own cost and price information.
						</p>
						<p className={classes.paragraphText}>
							The O-SHEC Tool can be used retrospectively or predictively. A
							retrospective analysis requires that a farmer has already adopted
							soil health practices and has already observed and documented
							economic costs and benefits from doing so. A predictive analysis
							requires that a farmer has not adopted or has just begun
							experimenting with one or more soil health practices and would
							like to estimate the change in annual net income by adopting one
							or more practices.
						</p>
						<p className={classes.paragraphText}>
							This online tool is still in development and only uses annual
							average values for analysis. A future predictive version will
							estimate the effects of potential soil organic matter improvements
							with soil health practice adoption and capture yield, soil
							fertility, and soil water storage capacity benefits.
						</p>

						<p className={classes.paragraphText}>
							This Online Tool is a simpler, more straightforward version of
							American Farmland Trust’s Excel-based Retrospective-SHEC and
							Predictive-SHEC Tools. These two Excel-based tools are more
							comprehensive and, are accompanied by two Word-based
							questionnaires. AFT used the R-SHEC tool and questionnaire and
							other resources to interview and publish 10 case studies of soil
							health successful farms in California, Illinois, Ohio, and New
							York as part of a USDA Conservation Innovation Grant, "Quantifying
							the Economic and Environmental Benefits of Soil Health." AFT has
							publicly released the row-crop Retrospective Soil Health Case
							Study Tool Kit, which includes over 20 Word- and Excel-based
							resources to enable our fellow conservationists to conduct their
							own case studies. Links to the tool kit and case studies as well
							as a summary of the findings are available on the References tab
							in this online tool. To learn more and stay up to date on these
							Tools, please visit{" "}
							<a
								href="https://farmland.org/soil-health-case-studies-methods/"
								rel="noreferrer"
								target="_blank"
							>
								soil health case studies
							</a>{" "}
							page.
						</p>

						<span className="paras">
							<span style={{fontWeight: 600, fontSize: "1.125rem"}}>
								Enter the tool:&nbsp;{" "}
							</span>
							<Link
								to="/calculator"
								style={{color: "inherit", textDecoration: "none"}}
							>
								<Button
									style={{
										height: "40px",
										fontSize: "1.0rem",
										fontWeight: 600,
										textTransform: "capitalize",
										backgroundColor: "green",
										color: "white",
									}}
								>
									Soil Health Economic Calculator
								</Button>
							</Link>
						</span>
					</div>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default About;
