let config: {
	apiUrl: string;
	faqUrl: string;
	crops: any;
	defaultTillageOption: any;
	defaultFertilizerOption: any;
	showProfileFields: any;
};

//TODO: update crops to be fetched from the database only once on user login
let baseConfig = {
	faqUrl: "https://opensource.ncsa.illinois.edu/confluence/display/FD/SHEC+FAQ",
	crops: {
		1: {name: "Corn", unit: "bushel"},
		2: {name: "Soybean", unit: "bushel"},
		3: {name: "Wheat", unit: "bushel"},
		4: {name: "Hay", unit: "ton"},
		5: {name: "Stockers", unit: "lbs"},
	},
	defaultTillageOption: {id: 0, name: "--Select--"},
	defaultFertilizerOption: {id: 0, name: "--None--"},
	showProfileFields: false
};

config = {...baseConfig, apiUrl: "/"};

export default config;
