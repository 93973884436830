import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../styles/home.css";

const Layout = (props: { children: React.ReactNode }) => {
	const styles: { [name: string]: React.CSSProperties } = {
		container: {
			marginTop: 80,
		},
	};

	return (
		<div>
			<Header />
			<div className="mainContent" style={styles.container}>
				{props.children}
			</div>
			<Footer />
		</div>
	);
};

export default Layout;
