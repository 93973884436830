import React, {ReactElement, useEffect, useState} from "react";
import "../../styles/home.css";
import {Button, Tooltip} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {apiClient} from "../../utils/axiosClients";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import config from "../../app.config";
import {fieldNameTooltip, fieldNameEditTooltip} from "../../app.messages";

interface Field{
	id: number;
	name:string;
}

interface CropOption{
	id: number;
	name: string;
	unit: string;
}

const useStyle = makeStyles((theme) => ({
	addFieldButton: {
		backgroundColor: "transparent"
	},
	deleteButton: {
		margin: theme.spacing(1),
	},
	addFieldText: {
		color: "green",
		padding: "8px"
	},
	addDialogFieldText: {
		color: "black",
		margin: "12px 8px"
	},
	addDialogAcres: {
		color: "black",
		margin: "12px 8px",
		width: "160px"
	},
	addDialogName: {
		color: "black",
		margin: "8px 8px",
		width: "250px"
	},
	fieldCard: {
		margin: "8px auto",
		textAlign: "left",
		borderRadius: "2px 2px 0 0",
		backgroundColor: "#DEE4E8",
		padding: "8px 8px 8px 12px",
		cursor: "pointer",
	},
	selectedFieldCard: {
		margin: "8px auto",
		width: "85%",
		textAlign: "left",
		borderRadius: "2px 2px 0 0",
		backgroundColor: "darkgrey",
		padding: "4px 8px 4px 12px",
	},
	myFields: {
		textAlign: "left",
		padding: "5px 15px",
		fontSize: "18px",
		fontWeight: 600
	},
	autocomplete: {
		margin: "10px 20px 10px 8px",
		textAlign: "right",
		width: "250px",
	},
	miniButtons: {
		width: "25px",
		minWidth: "25px",
		padding: "0px"
	},
	noCrops: {
		padding: "8px",
		fontWeight: 600,
		fontSize: "1.08rem",
		color: "darkgreen"
	}
}));

const Fields = (props: {fieldsList: Array<Field>, selFieldId: null|number, parentCallBack: any}) => {

	const dispatch = useDispatch();
	const classes = useStyle();
	const [cropOptions, setCropOptions] = useState<[] | [CropOption]>([]);
	const [selField, setSelField] = React.useState<null|number>(props.selFieldId);
	const [fieldInfo, setFieldInfo] = React.useState({name: "", crop_id: 0, benchmark_acres: "", current_acres: ""});

	const [open, setOpen] = React.useState(false);
	const [popupMode, setPopupMode] = React.useState("add");
	const [apiResponse, setApiResponse] = useState("na");
	let fieldCards: ReactElement[] = [];

	useEffect(() => {
		const fetchCrops = async () => {
			setApiResponse("na");
			await apiClient.get(
				"crops"
			).then(result => {
				if (result.status === 200) {
					setApiResponse("success");
					let results = result.data.map((row: CropOption) => {
						return ({id: row.id, name: row.name, unit: row.unit});
					});
					results.unshift({id: 0, name: "--Select--", unit: ""});
					setCropOptions(results);
				}
				else {
					setApiResponse("failure");
					console.error(`Failure fetching crops. API Response: ${result}`);
				}
			}).catch((e) => {
				setApiResponse("failure");
				console.error(`Error fetching crops from api: ${e}`);
			});
		};
		fetchCrops();
	}, [open]);

	useEffect(() => {
		setSelField(props.selFieldId);
	}, [props.selFieldId]);

	useEffect(() => {

		if (popupMode === "edit"){
			const fetchField = async () => {
				await apiClient.get(
					`fields/${selField}`).then(result => {
					if (result.status === 200) {
						let fieldJson = result.data;
						setFieldInfo({
							name: fieldJson.name,
							crop_id: fieldJson.crop_id,
							benchmark_acres: fieldJson.benchmark_acres !== null ? fieldJson.benchmark_acres.toString() : "",
							current_acres: fieldJson.current_acres !== null ? fieldJson.current_acres.toString() : ""
						});
					}
					else {
						console.error(`Failure fetching field. API Response: ${result}`);
					}
				}).catch((e) => {
					console.error(`Error fetching field from api: ${e}`);
				});
			};
			fetchField();
		}
		else {
			setFieldInfo({name: "", crop_id: 0, benchmark_acres: "", current_acres: ""});
		}

	}, [open, popupMode, selField]);

	const handleAddClickOpen = () => {
		setOpen(true);
		setPopupMode("add");
	};

	const handleClose = () => {
		setOpen(false);
		setFieldInfo({name: "", crop_id: 0, benchmark_acres: "", current_acres: ""});
	};

	function validateForm() {
		if (!fieldInfo.name || fieldInfo.crop_id <= 0 || !fieldInfo.benchmark_acres || !fieldInfo.current_acres){
			return true;
		}
		return fieldInfo.benchmark_acres !== fieldInfo.current_acres;
	}

	const handleInputChange = (e: any) => {
		const {name, value} = e.target;
		setFieldInfo({...fieldInfo, [name]: value});
	};
	const addField = () => {
		// TODO input validation
		if (!fieldInfo.name) {
			console.log("Missing name!");
			return;
		}
		const postField = async () => {

			await apiClient.post("fields", fieldInfo, {
				headers: {
					"Content-Type": "application/json"
				}}).then( result => {
				if (result.status !== 201){
					console.error(`Failure creating the new field. API Response: ${result}`);
				}
				else {
					window.location.reload(); // TODO Change to soft refresh of fields
				}
			}).catch((e) => {
				console.error(`Error creating the new field from api: ${e}`);
			});
		};

		const updateField = async () => {
			apiClient.put(`fields/${selField}`, fieldInfo, {
				headers: {
					"Content-Type": "application/json"
				}}).then( result => {
				if (result.status !== 200){
					setApiResponse("failure");
					console.error(`Failure updating the field. API Response: ${result}`);
				}
				else {
					setApiResponse("success");
					window.location.reload();
					// return success message
				}
			}).catch((e) => {
				console.error(`Error updating the field from api: ${e}`);
			});
		};
		popupMode === "edit" ? updateField() : postField();
		setOpen(false);
	};

	const handleEditField = () => {
		setOpen(true);
		setPopupMode("edit");
	};

	const handleDeleteField = () => {
		apiClient.delete(`fields/${selField}`)
			.then( result => {
				if (result.status !== 200){
					console.error(`Failure creating the new field. API Response: ${result}`);
				}
				else {
					window.location.reload();
				}
			}).catch((e) => {
				console.error(`Error creating the new field from api: ${e}`);
			});
	};

	props.fieldsList.forEach(function(field: any){

		if (selField === field.id || props.selFieldId === field.id){

			fieldCards.push(
				<div style={{display: "flex", backgroundColor: "darkgrey"}} key={field.id}>
					<div className={classes.selectedFieldCard} onClick={() => {
						dispatch({type: "SET_FIELD", fieldId: field.id});
						dispatch({type: "SET_CROP_ID", cropId: field.crop_id});
						dispatch({type: "SET_CROP_NAME", cropName: config.crops[field.crop_id].name});
						dispatch({type: "SET_CROP_UNIT", cropUnit: config.crops[field.crop_id].unit});
						dispatch({type: "SET_CROP_ACRES", cropAcres: field.current_acres});
						setSelField(field.id);
						props.parentCallBack(field.id);
					}}>
						{field.name}
					</div>

					<Button className={classes.miniButtons} onClick={handleEditField}
							disableRipple={true} disableFocusRipple={true} style={{backgroundColor: "transparent"}}>
						<EditIcon/>
					</Button>

					<Button className={classes.miniButtons} onClick={handleDeleteField}
							disableRipple={true} disableFocusRipple={true} style={{backgroundColor: "transparent"}}>
						<DeleteForever/>
					</Button>
				</div>
			);
		}
		else {
			fieldCards.push(
				<div className={classes.fieldCard} key={field.id} onClick={() => {
					dispatch({type: "SET_FIELD", fieldId: field.id});
					dispatch({type: "SET_CROP_ID", cropId: field.crop_id});
					dispatch({type: "SET_CROP_NAME", cropName: config.crops[field.crop_id].name});
					dispatch({type: "SET_CROP_UNIT", cropUnit: config.crops[field.crop_id].unit});
					dispatch({type: "SET_CROP_ACRES", cropAcres: field.current_acres});
					setSelField(field.id);
					props.parentCallBack(field.id);
				}}>
					{field.name}
				</div>
			);
		}
	});

	return (
		<div>
			<div className="addFieldSection">
				<Button onClick={handleAddClickOpen} disableRipple={true} disableFocusRipple={true} style={{backgroundColor: "transparent"}}>
					<AddCircleRoundedIcon fontSize="large" style={{color: "green"}}/>
					<h2 className={classes.addFieldText}>
						Add Crop
					</h2>
				</Button>
			</div>

			{fieldCards.length > 0 ?
				<div>
					<div className={classes.myFields}> My Crops</div>
					<div className="cardContainer">{fieldCards} </div>
				</div>
				:
				<div className={classes.noCrops}>
					{apiResponse === "success" ? "Hi! Looks like you haven't added any information about your crops yet." +
							" Please use the \"Add Crop\" button above to get started." : "" }
				</div>
			}

			<div>
				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
					<DialogTitle id="form-dialog-title">
						{popupMode === "edit" ? "EDIT CROP" : "ADD A CROP"}
					</DialogTitle>
					<div className="alertBanner" style={{maxWidth: "420px"}}>
						Before and after acres must be the same. Currently the SHEC Tool is unable to evaluate changes in crop rotation.
					</div>
					<DialogContent>
						<Tooltip title={<span style={{fontSize: "0.8rem"}}>
							{popupMode === "edit" ? fieldNameEditTooltip : fieldNameTooltip}
						</span>} placement="right">
							<TextField
							className={classes.addDialogName}
							name="name"
							required
							label="Field Name"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={handleInputChange}
							value={fieldInfo.name}
							/>
						</Tooltip>
						<br/>

						<Autocomplete className={classes.autocomplete} options={cropOptions}
									  // defaultValue={{id: 0, name: "--Select--", unit: ""}}
							          value={
										  fieldInfo.crop_id > 0 ?
											  {id: fieldInfo.crop_id, name: config.crops[fieldInfo.crop_id].name, unit: config.crops[fieldInfo.crop_id].unit}
											  : {id: 0, name: "--Select--", unit: ""}
							          }
									  getOptionSelected={(option: CropOption, value: CropOption) => option.id === value.id}
									  getOptionLabel={(option: CropOption) => option.name}
									  renderInput={(params) =>
										  (<TextField {...params}
													  label="Crop"
													  required={true}
													  variant="outlined"
													  InputLabelProps={{shrink: true}}
										  />)
									  }
									  onChange = {(e, v: null | CropOption) =>
										  setFieldInfo({...fieldInfo, crop_id: v ? v.id : 0})}
						/>

						<TextField
							className={classes.addDialogAcres}
							name="benchmark_acres"
							required
							label="Before Acres"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={handleInputChange}
							value={fieldInfo.benchmark_acres}
						/>
						<TextField
							className={classes.addDialogAcres}
							name="current_acres"
							required
							label="After Acres"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={handleInputChange}
							value={fieldInfo.current_acres}
						/>
					</DialogContent>
					<DialogActions
						// style={{justifyContent: "flex-start"}}
					>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button disabled={validateForm()} onClick={addField} color="primary">
							{popupMode === "edit" ? "Update" : "Submit"}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};

export default Fields;
