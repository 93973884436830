const initState = {
	cropId: 1,
	cropName: "Corn",
	cropUnit: "bushel",
	cropAcres: 0,
	fieldInfoJson: {}
};

const cropField = (state = initState,
				   action: { type: string; cropId: number; cropName:string; cropUnit: string; cropAcres:string; fieldInfoJson: any }) => {
	switch (action.type) {
		case "SET_CROP_ID":
			return {
				...state,
				cropId: action.cropId
			};
		case "SET_CROP_NAME":
			return {
				...state,
				cropName: action.cropName
			};
		case "SET_CROP_UNIT":
			return {
				...state,
				cropUnit: action.cropUnit
			};
		case "SET_CROP_ACRES":
			return {
				...state,
				cropAcres: action.cropAcres
			};
		case "SET_FIELD_INFO_JSON":
			return {
				...state,
				fieldInfoJson: action.fieldInfoJson
			};
		default:
			return state;
	}
};

export default cropField;
