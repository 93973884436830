export default function authReducer(state, action) {
	switch (action.type) {
		case "AUTH_LOGIN":
			return {user: action.payload};
		case "AUTH_LOGOUT":
			return {};
		default:
			return state || {};
	}
}
