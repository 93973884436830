import React, {useEffect} from "react";
import "../../styles/home.css";
import {useSelector} from "react-redux";
import Login from "./Login";

const Authorizer = (props: any) => {
	const state: any = useSelector(state => state),
		{auth} = state;

	return (
		<div>
			{
				auth.user ? props.children : <Login/>
			}
		</div>
	);
};


export default Authorizer;
