import React, {useMemo, useCallback, ReactElement} from "react";
import "../../styles/home.css";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ComponentTextfield from "../styledControls/ComponentTextfield";
import {apiClient} from "../../utils/axiosClients";
import config from "../../app.config";

interface FertilizerOption {
	id: number;
	name: string;
}

interface ProductOption {
	id: number;
	name: string;
	unit: string;
	price: number;
}

interface FertilizerType {
	idx: number;
	applicatorOptions: Array<FertilizerOption>;
	productOptions: Array<ProductOption>;
	selectedId: number;
	selectedProducts: Array<{ id: number; amount: number; price: number }>;
	overridePrices: boolean;
	parentCallBack: any; // (id: null | number) => void;
}

const useStyle = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		padding: "2px 10px",
		// minWidth: "320px",
		// maxWidth: "340px",
		minWidth: "365px",
	},
}));

const Fertilizer = ({
	idx: fertIndex,
	applicatorOptions,
	productOptions,
	selectedId: applicatorId,
	selectedProducts: products,
	overridePrices,
	parentCallBack,
}: FertilizerType) => {
	const classes = useStyle();

	const updateFertilizer = useCallback(
		(values) =>
			parentCallBack(fertIndex, {applicatorId, products, ...values}),
		[fertIndex, applicatorId, products]
	);

	const selectedOption = useMemo(() => {
			return (
				applicatorOptions.find(
					(row: FertilizerOption) => row.id === applicatorId
				) || config.defaultFertilizerOption
			);
		}, [applicatorId, applicatorOptions]),
		productInputs = useMemo(() => {
			let productsInputs: Array<ReactElement> = [];
			products.forEach((element, idx) => {
				let matched = productOptions.find(function (entry) {
					return entry.id === element.id;
				});
				if (!matched) {
					return;
				}
				const setAmount = (amount: number) =>
						updateFertilizer({
							products: products.map((product, i) =>
								idx === i ? {...product, amount} : product
							),
						}),
					setPrice = (price: number) =>
						updateFertilizer({
							products: products.map((product, i) =>
								idx === i ? {...product, price} : product
							),
						});
				productsInputs.push(
					overridePrices ? (
						<div
							key={idx}
							style={{display: "flex", alignItems: "center", marginRight: 13}}
						>
							<ComponentTextfield
								name={`${matched.name} Amount`}
								unit={matched.unit}
								selectedValue={element.amount}
								parentCallBack={setAmount}
								widthpx="155px"
							/>
							<div style={{flex: 1, textAlign: "center", marginLeft: 13}}>
								@
							</div>
							<ComponentTextfield
								name={`${matched.name} Price`}
								unit={priceUnit(matched.unit)}
								selectedValue={element.price}
								parentCallBack={setPrice}
								widthpx="155px"
							/>
						</div>
					) : (
						<ComponentTextfield
							name={matched.name}
							unit={matched.unit}
							selectedValue={element.amount}
							parentCallBack={setAmount}
							widthpx="155px"
						/>
					)
				);
			});
			return productsInputs;
		}, [products, productOptions, overridePrices]);

	async function setApplicatorId(applicatorId: any) {
		if (applicatorId === 0) {
			updateFertilizer({applicatorId: 0, products: []});
			return;
		}
		const result = await apiClient.get(
			`fertilizer-applications/${applicatorId}/fertilizer-application-products`
		);
		if (result.status === 200) {
			updateFertilizer({
				applicatorId,
				products: result.data.map((row: any) => ({
					id: row.id,
					amount: null,
					price: null,
				})),
			});
		}
		else {
			console.error(
				`Failure fetching fertilizer products. API Response: ${result}`
			);
		}
	}

	return (
		<div>
			<FormControl variant="outlined" className={classes.formControl}>
				<Autocomplete
					options={applicatorOptions}
					value={applicatorOptions ? selectedOption : null}
					getOptionSelected={(
						option: FertilizerOption,
						value: FertilizerOption
					) => option.id === value.id}
					getOptionLabel={(option: FertilizerOption) => option.name}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Fertilizer"
							variant="outlined"
							InputLabelProps={{shrink: true}}
						/>
					)}
					onChange={(event, value: null | FertilizerOption) => {
						setApplicatorId(value ? value.id : 0);
					}}
				/>
			</FormControl>

			{productInputs}
		</div>
	);
};

export default Fertilizer;

function priceUnit(unit: string) {
	if (unit === "lbs") {
		return "$ / lb";
	}
	else {
		return "$ / {unit}";
	}
}
