import React, {ChangeEvent, useState} from "react";
import Layout from "./common/Layout";
import LoadingOverlay from "react-loading-overlay-ts";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CropHistory from "./CropHistory";
import CoverCropHistory from "./CoverCropHistory";
import FieldSummary from "./FieldSummary";
import PartialBudgetSummary from "./PartialBudgetSummary";
import {apiClient} from "../utils/axiosClients";
import Fields from "./controls/Fields";
import {Grid} from "@material-ui/core";
import Authorizer from "./common/Authorizer";
import {useDispatch, useSelector} from "react-redux";
import PartialBudgetQuadrants from "./PartialBudgetQuadrants";
import config from "../app.config";
import Guidance from "./Guidance";
/* eslint-disable */
const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		"& div.MuiTabs-scroller": {
			position: "fixed",
			background: "white",
			zIndex: 3,
		},
	},
	disabledRoot: {
		flexGrow: 1,
		filter: "blur(2px)",
		pointerEvents: "none",
	},
	indicator: {
		backgroundColor: "darkgreen",
	},
	scroll: {
		position: "fixed",
		background: "white",
		zIndex: 3,
	},
	tabText: {
		fontWeight: 600,
	},
}));

const HomePage = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [value, setValue] = React.useState("crop");
	const [fields, setFields] = React.useState<
		Array<{ id: number; name: string }>
	>([]);
	const [selFieldId, setSelFieldId] = useState<null | number>(null);
	const store: any = useSelector((store) => store);
	let loading = store.appStates.loading;

	const handleChange = (event: ChangeEvent<{}>, newValue: string) => {
		setValue(newValue);
	};

	React.useEffect(() => {
		const fetchFields = async () => {
			dispatch({ type: "SET_LOADING", loading: true });
			await apiClient
				.get("fields")
				.then((result) => {
					if (result.status === 200) {
						if (result.data.length > 0) {
							setSelFieldId(result.data[0].id);
							dispatch({ type: "SET_FIELD", fieldId: result.data[0].id });
							dispatch({ type: "SET_CROP_ID", cropId: result.data[0].crop_id });
							dispatch({
								type: "SET_CROP_NAME",
								cropName: config.crops[result.data[0].crop_id].name,
							});
							dispatch({
								type: "SET_CROP_UNIT",
								cropUnit: config.crops[result.data[0].crop_id].unit,
							});
							dispatch({
								type: "SET_CROP_ACRES",
								cropAcres: result.data[0].current_acres,
							});
						}
						setFields(result.data);
					} else {
						console.error(`Failure fetching fields. API Response: ${result}`);
					}
					dispatch({ type: "SET_LOADING", loading: false });
				})
				.catch((e) => {
					dispatch({ type: "SET_LOADING", loading: false });
					console.error(`Error fetching fields from api: ${e}`);
				});
		};
		fetchFields();
	}, [dispatch]);

	return (
		<Authorizer>
			<Layout>
				<LoadingOverlay active={loading} spinner text="Loading...">
					<Grid container>
						<Grid item className="fieldsPanel">
							<Fields
								fieldsList={fields}
								selFieldId={selFieldId}
								parentCallBack={setSelFieldId}
							/>
						</Grid>

						<Grid item className="tabsPanel">
							{selFieldId ? (
								<Paper className={classes.root}>
									<Tabs
										className={classes.root}
										value={value}
										onChange={handleChange}
										TabIndicatorProps={{ className: classes.indicator }}
									>
										<Tab
											label="Guidance"
											value="instructions"
											className={classes.tabText}
										/>
										<Tab
											label="Crop Info"
											value="crop"
											className={classes.tabText}
										/>
										<Tab
											label="Cover Crop Info"
											value="covercrop"
											className={classes.tabText}
										/>
										{/*<Tab label="Field Summary" value="summary" className={classes.tabText} />*/}
										<Tab
											label="Partial Budget Analysis"
											value="pba"
											className={classes.tabText}
										/>
										<Tab
											label="Budget Summary"
											value="budgetsummary"
											className={classes.tabText}
										/>
									</Tabs>
									{value === "crop" ? (
										<CropHistory fieldId={selFieldId} />
									) : (
										<div />
									)}
									{value === "covercrop" ? (
										<CoverCropHistory fieldId={selFieldId} />
									) : (
										<div />
									)}
									{value === "summary" ? <FieldSummary /> : <div />}
									{value === "pba" ? (
										<PartialBudgetQuadrants fieldId={selFieldId} />
									) : (
										<div />
									)}
									{value === "budgetsummary" ? (
										<PartialBudgetSummary fieldId={selFieldId} />
									) : (
										<div />
									)}
									{value === "instructions" ? <Guidance /> : <div />}
								</Paper>
							) : !loading ? (
								<Guidance />
							) : (
								<div />
							)}
						</Grid>
					</Grid>
				</LoadingOverlay>
			</Layout>
		</Authorizer>
	);
};

export default HomePage;
