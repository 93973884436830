import appConfig from "../app.config";

const apiClient = {
	async fetch(path, body, options) {
		let headers = {
			Accept: "application/json",
			...(options && options.headers),
		};
		if (body) {
			const csrftoken = document.cookie
				.split(";")
				.find((cookie) => cookie.trim().startsWith("csrftoken="))
				.split("=")[1];
			options = {
				...options,
				body: body instanceof FormData ? body : JSON.stringify(body),
			};
			headers = {
				...headers,
				"X-CSRFToken": csrftoken,
			};
			if (!(body instanceof FormData)) {
				headers["Content-Type"] = "application/json";
			}
		}
		const response = await fetch(`${appConfig.apiUrl}${path}`, {
				...options,
				headers,
			}),
			data = await response.json();
		return {
			status: response.status,
			data: data.list ? data.list : data,
		};
	},
	async get(path, body, options) {
		return this.fetch(path, body, options);
	},
	async post(path, body, options) {
		options = {method: "POST", ...options};
		return this.fetch(path, body, options);
	},
	async put(path, body, options) {
		// Old API treated PUT as partial update, use PATCH instead
		options = {method: "PATCH", ...options};
		return this.fetch(path, body, options);
	},
	async delete(path, body, options) {
		options = {method: "DELETE", ...options};
		return this.fetch(path, body, options);
	},
};

export default apiClient;
