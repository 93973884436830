import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {WithStyles, createStyles} from "@material-ui/core";
import {TextField, InputAdornment} from "@material-ui/core";


const styles = () => createStyles({
	root: {
		margin: "10px 5px 10px 20px",
		textAlign: "right",
		lineHeight: "75px",
		// width: "130px",
		"& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
			display: "none"
		}
	}
});

interface Props extends WithStyles<typeof styles> {
	name: string;
	unit?: string;
	selectedValue: null | number;
	parentCallBack: any;
	widthpx?: string;
}

const ComponentTextfield = withStyles(styles) (({name, unit, selectedValue: value, parentCallBack, widthpx, classes}: Props) => {

	let adornment;
	if (unit === "$"){
		adornment = {startAdornment: <InputAdornment position="start">$</InputAdornment>};
	}
	else {
		adornment = {endAdornment: <InputAdornment position="end"> {unit} </InputAdornment>};
	}

	let color = "";
	if (name.toLowerCase() === "before"){
		color = "#3f51b5";
	}
	else if (name.toLowerCase() === "after"){
		color = "darkgreen";
	}

	// { target: { value: React.SetStateAction<string | null>; }; }
	const handleChange = (event: any) => {
		if (typeof event.target.value === "string") {
			parentCallBack(event.target.value ? parseFloat(event.target.value) : null);
		}
	};

	return (
		<TextField classes={classes} label={name} variant="outlined" type="number"
			InputLabelProps={{shrink: true, style: {color: color}}}
			InputProps={adornment}
			onChange={handleChange}
			value={(value !== null && value >= 0) ? value : ""} style={{width: widthpx ? widthpx : "130px"}}

		/>
	);
});

export default ComponentTextfield;
