import React from "react";
import Layout from "./Layout";
import "../../styles/home.css";
import {Grid} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

const StyledTableCell = withStyles((theme) => ({
	head: {
		padding: "10px 0px",
		lineHeight: "110%",
		// backgroundColor: "#F0F0F0",
		backgroundColor: "#FFFFFF",
		color: "#000000",
		fontWeight: 800
	},
	body: {
		padding: "8px",
		fontSize: 14,
		"& input": {
			border: "solid 1px",
			borderColor: "#455A64",
			width: "75%",
			lineHeight: "120%",
		}
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	mainText: {
		margin: "0px",
		padding: "0px 0px",
		lineHeight: "160%"
	},
	paragraphText: {
		color: "#455A64",
		paddingBottom: "0px",
		paddingLeft: "50px",
		paddingRight: "50px",
		textAlign: "justify",
	},
	calculatorLinkText: {
		color: "#455A64",
		paddingBottom: "10px",
		paddingTop: "20px",
		textAlign: "center",
	},
	table: {
		padding: "0px 50px 15px 50px",
		margin: "0px",
	},
});

const sources = {
	"data_sources": [
		{
			"title": "Machinery Cost Estimates",
			"geographic_relevance": "Mid-Western",
			"link_text": "Field Operations, Farm Business Mgt., University of Illinois, August 2019",
			"url": "http://farmdoc.illinois.edu/manage/machinery/field_operations_2017.pdf"
		},
		{
			"title": "Machinery Costs for Fertilizer Application",
			"geographic_relevance": "Mid-Western",
			"link_text": "Mid-Western Iowa Farm Custom Rate Survey, Iowa State University Extension and Outreach, 2019, Revised March 2019",
			"url": "https://www.extension.iastate.edu/agdm/crops/pdf/a3-10.pdf"
		},
		{
			"title": "NASS Index of Prices Paid",
			"geographic_relevance": "National",
			"link_text": "Producer Prices Paid Index, National Agricultural Statistics Service",
			"url": "https://www.nass.usda.gov/Charts_and_Maps/graphics/data/all_prod.txt"
		},
		{
			"title": "Crop Prices - Non-organic",
			"geographic_relevance": "National",
			"link_text": "Crop Values 2019 Summary, February 2020, USDA, National Agricultural Statistics Service",
			"url": "https://www.nass.usda.gov/Publications/Todays_Reports/reports/cpvl0220.pdf"
		},
		{
			"title": "Crop Prices - Organic, Corn, Soybeans, Hay, Wheat",
			"geographic_relevance": "National",
			"link_text": "National Organic Grain and Feedstuffs Report, National Agricultural Statistics Service, January 29, 2020",
			"url": "https://www.ams.usda.gov/mnreports/lsbnof.pdf"
		},
		{
			"title": "Fertilizers - The prices of five of the eight major fertilizers",
			"geographic_relevance": "National",
			"link_text": "DTN Retail Fertilizer Trends",
			"url": "https://www.dtnpf.com/agriculture/web/ag/crops/article/2020/11/18/dap-prices-close-2019-levels-see"
		}
	]
};

const References = () => {
	const classes = useStyles();

	return (
		<Layout>
			<Grid container item xs={12} >
				<Grid className={classes.mainText} item xs={12} >
					<div>
						<h2 className="secondary-color">References for the Soil Health Economic Calculator</h2>
						<br />
						<div className={classes.paragraphText}>
							<h3>Soil Health Case Studies</h3>
							<ul>
								<li><b>Two-Page Economic and Environmental Soil Health Case Studies by AFT-NRCS provided in three web locations:</b>
									<ul>
										<li><a href="https://farmlandinfo.org/publications/soil-health-case-studies/" target="_blank" rel="noreferrer">https://farmlandinfo.org/publications/soil-health-case-studies/</a></li>
										<li><a href="https://www.nrcs.usda.gov/wps/portal/nrcs/detail/national/technical/econ/data/?cid=nrcseprd1298423" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/detail/national/technical/econ/data/?cid=nrcseprd1298423</a></li>
										<li><a href="https://www.nrcs.usda.gov/wps/portal/nrcs/detail/national/soils/health/?cid=NRCSEPRD1470394" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/detail/national/soils/health/?cid=NRCSEPRD1470394</a></li>
									</ul>
								</li>
								<li><b>AFT's Soil Health Case Studies Methods Description:</b>
									<ul>
										<li><a href="https://farmland.org/soil-health-case-studies-methods/" target="_blank" rel="noreferrer">https://farmland.org/soil-health-case-studies-methods/</a></li>
									</ul>
								</li>
								<li><b>AFT's Retrospective Soil Health Economic Calculator and Case Study Tool Kit - </b>Download the 20 Excel- and Word-based resources in to conduct your own economic, water quality, and climate outcomes analyses and publish your own case studies featuring already "soil health successful" farmers:
									<ul>
										<li><a href="https://farmland.org/cig-retrospective-soil-health-economic-calculator/" target="_blank" rel="noreferrer">https://farmland.org/cig-retrospective-soil-health-economic-calculator/</a></li>
									</ul>
								</li>
								<li><b>Information on Soil Health by NRCS:</b>
									<ul>
										<li><a href="https://www.nrcs.usda.gov/wps/portal/nrcs/main/soils/health/" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/main/soils/health/</a></li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} >
					<div className={classes.paragraphText}>
						<h3>Data Sources</h3>
					</div>
					<div className={classes.table}>
						<Table>
							<TableHead>
								<TableRow>
									<StyledTableCell style={{width: "30%"}} align="center">Item</StyledTableCell>
									<StyledTableCell style={{width: "10%"}} align="center">Geographic Relevance</StyledTableCell>
									<StyledTableCell align="center">Source</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{sources.data_sources.map((row, i:number) => (
									<StyledTableRow key={i}>
										<StyledTableCell align="left">{row.title}</StyledTableCell>
										<StyledTableCell align="center">{row.geographic_relevance}</StyledTableCell>
										<StyledTableCell align="left"><a href={row.url} target="_blank" rel="noreferrer">{row.link_text}</a></StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default References;
