import React from "react";


const FieldSummary = () => {

	return (
		<h3>Field Summary Page</h3>
	);
};

export default FieldSummary;
