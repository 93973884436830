import React from "react";
import "../../styles/home.css";

import packageJson from "../../../package.json";
import {Toolbar} from "@material-ui/core";

// import NCSALogo from "../../images/ncsa-logo.png";

const Footer = () => {

	return (
		<Toolbar className="footer">
			<div className="versionSection">
				v{packageJson.version}
			</div>

			{/*<div className="footerLogoSection">*/}
			{/*	<a href="http://www.ncsa.illinois.edu" target="_blank" rel="noreferrer" className="footerlogo">*/}
			{/*		<img src={NCSALogo} alt="NCSA" title="National Center for Supercomputing Applications"*/}
			{/*			 style={{width: "140px"}} />*/}
			{/*	</a>*/}
			{/*</div>*/}

						
		</Toolbar>

	);
};

export default Footer;
